// app/components/dropdown/component_controller.js
import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
	static targets = [
										"filegallery", 
										"image_gallery",
										"file_gallery",
										"overlay",
										"imagetemplate",
										"filetemplate",
										"gallery",
										"empty",
										"fileupload",
										"image_fileupload", 
										"file_fileupload",
										"header",
										"file_good", 
										"file_unsupported"
									];
	
	static values = { 
		imageUrl: String, 
		fileUrl: String,
		imageExtensions: Array,
		modelExtensions: Array,
		preloadedImageCount: Number,
		preloadedFileCount: Number,
	};

	MODEL_FILE_COUNT = this.preloadedFileCountValue
	IMAGE_FILE_COUNT = this.preloadedImageCountValue

	connect() {
		this.debug("connect()")
		this.debug(`Image file extensions: ${this.imageExtensionsValue}`)
		this.debug(`Model file extensions: ${this.modelExtensionsValue}`)
		this.debug(`Image Upload URL: ${this.imageUrlValue}`)
		this.debug(`File Upload URL: ${this.fileUrlValue}`)
		this.debug(`Preloaded Image Count: ${this.preloadedImageCountValue}`)
		this.debug(`Preloaded File Count: ${this.preloadedFileCountValue}`)
	}

	debug(message) {
		console.log("UPLOADER COMPONENT: ", message)
	}

	// DRAG EVENTS

	onDragOver(event) {
		this.debug("onDragOver()")
		event.preventDefault();
		this.overlayTarget.classList.add('draggedover');
	}

	onDragLeave() {
		this.debug("onDragLeave()")
		this.overlayTarget.classList.remove('draggedover');
	}

	// DROP EVENTS

	onDrop(event) {
		this.debug("onDrop()")
		event.preventDefault();

		for (const file of event.dataTransfer.files) {
			this.addFile(file);
			this.overlayTarget.classList.remove("draggedover");
		}
	}

	// CLICK EVENTS

	click(event) {
		event.preventDefault();
		this.fileuploadTarget.click() // Click the file upload input to toggle the file selector dialogue
	}

	fileUploadClick(event) {
		event.preventDefault();
		this.file_fileuploadTarget.click() // Click the file upload input to toggle the file selector dialogue
	}

	image_click(event) {
		event.preventDefault();
		this.image_fileuploadTarget.click() // Click the image file upload input to toggle the file selector dialogue
	}	

	delete(event) {
		this.debug("delete()")
	 	event.preventDefault();

		if (event.target.classList.contains("delete")) {
			const ou = event.target.dataset.target;
			document.getElementById(ou).remove(ou);

			const mediatype =  event.target.dataset.objecttype;

			switch (mediatype) {
				case 'image':
					this.IMAGE_FILE_COUNT--
					this.debug(`Image File Count: ${this.IMAGE_FILE_COUNT}`)
					break
				case 'model':
					this.MODEL_FILE_COUNT--
					this.debug(`Model File Count: ${this.MODEL_FILE_COUNT}`)
					break
			}

			this.toggleImagePanel();
			this.toggleModelPanel();
		
		}
	}

	deleteExisting(event) {
		this.debug("deleteExisting()")
		event.preventDefault()
	}

	filechange(event) { // When files are added via the file selector dialogue
		this.debug("filechange()")
		for (const file of event.target.files) {
			this.addFile(file);
		}
	}

	filechange_images(event) { // When files are added via the file selector dialogue
		this.debug("filechange_images()")
		for (const file of event.target.files) {
			this.addFile(file);
		}
	}

	filechange_files(event) { // When files are added via the file selector dialogue
		this.debug("filechange_files()")
		for (const file of event.target.files) {
			this.addFile(file);
		}
	}

	toggleImagePanel() {
		if (this.IMAGE_FILE_COUNT == 0) {
			this.image_galleryTarget.classList.add('hidden')
		} else {
			this.image_galleryTarget.classList.remove('hidden')
		}
	}

	toggleModelPanel() {
		if (this.MODEL_FILE_COUNT == 0) {
			this.file_galleryTarget.classList.add('hidden')
		} else {
			this.file_galleryTarget.classList.remove('hidden')
		}
	}

	addFile(file) {
		this.debug("addFile()")

		// Do we accept this file type?
		if (!this.hasExtension(file.name, (this.imageExtensionsValue.concat(this.modelExtensionsValue)) )) {
			this.headerTarget.classList.add("bg-red-300")
			this.file_goodTarget.classList.add("hidden")
			this.file_unsupportedTarget.classList.remove("hidden")
			setTimeout(() => {
				this.headerTarget.classList.remove("bg-red-300")
				this.file_goodTarget.classList.remove("hidden")
				this.file_unsupportedTarget.classList.add("hidden")
			}, 2000);
			return
		}

		const isImage = file.type.match("image.*"), objectURL = URL.createObjectURL(file);

		if (isImage) {
			this.IMAGE_FILE_COUNT++ // Keep a count of attached images

			const clone = this.imagetemplateTarget.content.cloneNode(true)

			clone.querySelector(".uploaded-image").id = objectURL;
			clone.querySelector(".delete").dataset.target = objectURL;
			clone.querySelector(".delete").dataset.identifier = `image_${this.IMAGE_FILE_COUNT}`;
			clone.querySelector(".image_input").name = `bit[images_attributes][${this.IMAGE_FILE_COUNT}][imagefile]`;
			clone.querySelector(".delete").dataset.objecttype = 'image';

			isImage &&
				Object.assign(clone.querySelector("img"), {
					src: objectURL,
					alt: file.name
				});

			// Add the image template to the gallery
			this.image_galleryTarget.querySelector('.grid').prepend(clone);
			this.debug(`Image File Count: ${this.IMAGE_FILE_COUNT}`)

			this.toggleImagePanel();
			this.uploadFile("images", file); 

			// createDirectUploadController(file).start

		} else {

				const clone = this.filetemplateTarget.content.cloneNode(true)

			clone.querySelector(".filename").textContent = file.name;
			// clone.querySelector("li").id = objectURL;
			clone.querySelector(".uploaded-file").id = objectURL;
			clone.querySelector(".delete").dataset.target = objectURL;
			clone.querySelector(".delete").dataset.objecttype = 'model';
			clone.querySelector(".size").textContent =
				file.size > 1024
					? file.size > 1048576
						? Math.round(file.size / 1048576) + "mb"
						: Math.round(file.size / 1024) + "kb"
					: file.size + "b";

			this.MODEL_FILE_COUNT++

			this.debug(`Model File Count: ${this.MODEL_FILE_COUNT}`)

			this.file_galleryTarget.querySelector('tbody').prepend(clone);
			this.toggleModelPanel();
			this.uploadFile("files", file); 
		}

	}

	uploadFile(type, file) {
		this.debug(`Uploading file: ${type}`)
		const upload = type == "images" ? new DirectUpload(file, this.imageUrlValue) : new DirectUpload(file, this.fileUrlValue);

    upload.create((error, blob) => {
      if (error) {
        console.log("Error");
      } else {
				const hiddenField = document.createElement('input')
      	hiddenField.setAttribute("type", "hidden");
	      hiddenField.setAttribute("value", '');

				// Add the form fields to the page.
				if (type == 'images') {
					hiddenField.value = blob.signed_id
					hiddenField.name = `bit[images_attributes][${this.IMAGE_FILE_COUNT}][imagefile]`
				} else {
					hiddenField.value = blob.signed_id
					hiddenField.name = `bit[files][]`
				}
    	  document.querySelector('form').appendChild(hiddenField)
    	}
    });
  }

	hasExtension(fileName, exts) {
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
	}

}