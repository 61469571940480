// app/components/dropdown/bit_component_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["bitimage"]
		static values = { url: String };

    connect() {
			//this.bitimageTarget.src=this.urlValue
    }
}