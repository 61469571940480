import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["star"]
		static values = { 
			bitid: Number,
			favourited: Boolean
		}

		initialize() {
			this.favourited = this.favouritedValue
		}

    toggle() {
			fetch(`/bits/${this.bitidValue}/favourite`, {
				"method": "POST",
				"headers": {
					"content-type": "application/json",
					"accept": "application/json"
				}
			})
			.then(response => response.json())
			.then(data => {
				if (data.action == 'created') {
					this.starTarget.classList.add("fill-current")
					this.starTarget.classList.remove("hover:fill-current")
				}	else {
					this.starTarget.classList.add("fill-current")
					this.starTarget.classList.remove("fill-current")
				}
			})
			.catch(err => {
				console.log(err);
			});
    }
}