import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["flash"]

	connect() {
		//const _this = this
		setTimeout(() => {
			this.element.classList.add("opacity-0")
		}, 5000)
	}		

	close() {
		this.element.classList.add("opacity-0")
	}

}
