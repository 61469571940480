// app/components/dropdown/component_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["menu", "item"]

    connect() {
        console.log("Dropdown component")
    }

    toggleMenu() {
        this.menuTarget.classList.toggle('hidden');
    }

    hide(event) {
        if (this.element.contains(event.target) === false)  {
            this.menuTarget.classList.add('hidden');
        }
      }

    highlightMenuItem(event) {
        const menuItem = event.target
        menuItem.classList.remove('text-gray-700','bg-white');
        menuItem.classList.add('bg-indigo-500','text-white');
    }

    unhighlightMenuItem(event) {
        const menuItem = event.target
        menuItem.classList.add('text-gray-700');
        menuItem.classList.remove('bg-indigo-500', 'text-white');


    }
}