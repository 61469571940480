import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["panelDetails", "panelFiles", "buttonDetails", "buttonFiles"]
	init() {
		console.log("TABS connect")
	}

	connect() {
		console.log("TABS connect")
	}

	showFiles() {
		this.panelDetailsTarget.classList.add("hidden")
		this.panelFilesTarget.classList.remove("hidden")

		this.buttonDetailsTarget.classList.remove("border-indigo-600","text-indigo-600")
		this.buttonDetailsTarget.classList.add("border-transparent","text-gray-700","hover:text-gray-800","hover:border-gray-300")
		this.buttonFilesTarget.classList.remove("border-transparent","text-gray-700","hover:text-gray-800","hover:border-gray-300")
		this.buttonFilesTarget.classList.add("border-indigo-600","text-indigo-600")
	}

	showDetails() {
		this.panelFilesTarget.classList.add("hidden")
		this.panelDetailsTarget.classList.remove("hidden")

		this.buttonFilesTarget.classList.remove("border-indigo-600","text-indigo-600")
		this.buttonDetailsTarget.classList.add("border-indigo-600","text-indigo-600")
		this.buttonDetailsTarget.classList.remove("border-transparent","text-gray-700","hover:text-gray-800","hover:border-gray-300")
		this.buttonFilesTarget.classList.add("border-transparent","text-gray-700","hover:text-gray-800","hover:border-gray-300")
		//this.buttonDetailsTarget.classList.add("border-indigo-600","text-indigo-600")
	}
}


// <!-- Selected: "border-indigo-600 text-indigo-600", Not Selected: "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300" -->
           