import "./controllers"
import '../components';

// import THREE from './three.min'
// import './STLLoader'
// import './OrbitControls'

import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

ActiveStorage.start()
console.log("ActiveStorage started")
